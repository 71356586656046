import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import http from '@/services'
import { error } from '@/services/toast'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class LoginModal extends Vue {
  @Prop({ required: false })
  action!: string
  loading = false
  isLoading = false
  mail = ''

  mounted() {
    console.log('HOLAS')
  }

  close() {
    this.$emit('closeForm')
  }
  async confirmation() {
    try {
      await http.post('reset-password/send/', {
        email: this.mail,
      })
      this.$buefy.toast.open({
        message: 'Se envio una nueva contraseña a su correo',
        type: 'is-success',
      })
      this.close()
    } catch (error) {
      console.log(error)
      this.$buefy.toast.open({
        message: 'Ups, intentalo de nuevo',
        type: 'is-danger',
      })
    }
  }
}
